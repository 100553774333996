import { Button } from '@lemonenergy/lemonpie-components'
import { useNavigate } from '@remix-run/react'
import { useCallback, useState } from 'react'

import GirlWithLightBulb from '~/components/illustrations/GirlWithLightBulb'
import useAppNavigation from '~/hooks/useAppNavigation'
import LoadingFull from '~/ui/LoadingFull'
import {
  PageHeader,
  PageHeaderBackButton,
  PageHeaderNav,
} from '~/ui/PageHeader'

const ConnectivityIssues = () => {
  const { goBack } = useAppNavigation()
  const navigate = useNavigate()
  const [isReloadDisabled, setIsReloadDisabled] = useState(false)

  const handleReload = useCallback(async () => {
    if (window?.navigator?.onLine !== false) {
      try {
        setIsReloadDisabled(true)
        const request = new URL(self.location.origin)
        request.searchParams.set('rand', Date.now().toString())

        const response = await fetch(request.toString(), { method: 'HEAD' })

        if (!response.ok)
          throw Error('Verify network connection request failed.')

        navigate(0)
      } catch (e) {}
    }

    setIsReloadDisabled(true)
    setTimeout(() => {
      setIsReloadDisabled(false)
    }, 1000)
  }, [navigate])

  return (
    <>
      <PageHeader>
        <PageHeaderNav>
          <PageHeaderBackButton onClick={goBack} />
        </PageHeaderNav>
      </PageHeader>
      <div className="flex flex-col flex-1 justify-between">
        <div className="flex flex-col justify-center flex-1 gap-main my-0 mx-larger">
          {isReloadDisabled ? (
            <LoadingFull />
          ) : (
            <>
              <GirlWithLightBulb className="mb-larger" />
              <h1 className="typography-heading">Parece que a internet caiu</h1>
              <h2 className="typography-subheading [&&]:mb-small fg-soft">
                Veja como está sua conexão e tente de novo
              </h2>
            </>
          )}
        </div>

        <div className="m-large">
          <Button
            variant="primary"
            onClick={handleReload}
            disabled={isReloadDisabled}
          >
            Tentar de novo
          </Button>
        </div>
      </div>
    </>
  )
}

export default ConnectivityIssues
