import { Link, useLocation, useSearchParams } from '@remix-run/react'

import { Button } from '@lemonenergy/lemonpie-components'
import BoyWithMagnifyingGlass from '~/components/illustrations/BoyWithMagnifyingGlass'
import useAppNavigation from '~/hooks/useAppNavigation'
import useRefreshCache from '~/hooks/useRefreshCache'
import {
  PageHeader,
  PageHeaderBackButton,
  PageHeaderNav,
} from '~/ui/PageHeader'
import WhatsappButton from '~/ui/WhatsappButton'

const retryLabels = [
  {
    heading: 'Algo deu errado',
    subHeading:
      'Aconteceu um erro inesperado após a sua ação. Você pode voltar ou tentar de novo',
  },
  {
    heading: 'Parece que o erro continua',
    subHeading:
      'Pode ser alguma instabilidade na sua rede ou no sistema. Você pode voltar ou tentar de novo',
  },
  {
    heading: 'Hmm... que estranho!',
    subHeading:
      'Parece que é melhor esperar o problema ser resolvido. Você também pode falar com a gente pra te ajudarmos',
  },
]

const TryAgain = () => {
  const refreshCache = useRefreshCache()
  const { goBack } = useAppNavigation()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  let retryParams = searchParams.get('retries')
  let retryNumber = retryParams ? parseInt(retryParams) : 0

  const refresh = () => {
    refreshCache(location.pathname)
  }

  const actionButton =
    retryNumber < 2 ? (
      <Button asChild variant="primary">
        <Link to={`?retries=${retryNumber + 1}`} replace onClick={refresh}>
          Tentar de novo
        </Link>
      </Button>
    ) : (
      <div className="flex flex-col gap-small">
        <WhatsappButton />

        <Button variant="secondary" onClick={goBack}>
          Voltar
        </Button>
      </div>
    )

  return (
    <>
      <PageHeader>
        <PageHeaderNav>
          <PageHeaderBackButton onClick={goBack} />
        </PageHeaderNav>
      </PageHeader>
      <div className="flex flex-col flex-1 justify-between">
        <div className="flex flex-col justify-center flex-1 gap-main my-0 mx-larger">
          <BoyWithMagnifyingGlass className="mb-larger" />
          <h1 className="typography-heading">
            {retryLabels[retryNumber].heading}
          </h1>
          <h2 className="typography-subheading [&&]:mb-small fg-soft">
            {retryLabels[retryNumber].subHeading}
          </h2>
        </div>

        <div className="m-large">{actionButton}</div>
      </div>
    </>
  )
}

export default TryAgain
