import { Outlet } from '@remix-run/react'
import { useEffect } from 'react'

import BaseErrorPage from '~/components/ErrorPages/BaseErrorPage'
import { SurveyProvider } from '~/hooks/useCSATDialog'

export function ErrorBoundary() {
  return <BaseErrorPage />
}

// https://developer.apple.com/documentation/webkitjs/gestureevent
interface GestureEvent extends TouchEvent {
  scale?: number
}

const App = () => {
  useEffect(() => {
    const disableScale = (event: GestureEvent) => {
      if (event.scale !== undefined && event.scale !== 1) {
        event.preventDefault()
        event.stopImmediatePropagation()
        console.debug({ event })
      }
    }

    window.addEventListener('touchmove', disableScale, { passive: false })

    return () => {
      window.removeEventListener('touchmove', disableScale)
    }
  }, [])

  return (
    <SurveyProvider>
      <Outlet />
    </SurveyProvider>
  )
}

export default App
